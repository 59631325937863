@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  font-family: sans-serif;
  text-align: center;
}

.editor-height {
  height: calc(80vh - 165px);
}

.output-terminal-bg {
  background-color: #303841;
}

.output-terminal-height {
  height: 10vh;
}

.padding-top-left-1 {
  padding: 1rem 0rem 0rem 1rem;
}
